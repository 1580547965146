<template>
  <a-modal
    :closable="true"
    :visible="show"
    width="726px"
    footer=""
    @cancel="cancelDownload">
    <a-spin :spinning="imageCount === -1" size="large">
      <h1 data-cy="original-submissions-download-modal-title">{{ $t('components.dropdown.downloadOriginalData') }}</h1>
      <div>
        <div v-if="imageCount !== -1 && stage === DownloadStage.CHOOSE">
          <p class="report-text">{{ $t('components.description.originalSubmissionsImageSelection') }}</p>
          <a-select v-model="selectedImagesRequest" class="flag-picker" :placeholder="$t('components.labels.selectImageDownloadResolutionPlaceholder')"
            :get-popup-container="(triggerNode) => triggerNode.parentNode">
            <a-select-option value="originalResImages">{{ $t('components.dropdown.originalResolutionImages') }}</a-select-option>
            <a-select-option value="loResImages">{{ $t('components.dropdown.lowResolutionImages') }}</a-select-option>
            <a-select-option value="noImages">{{ $t('components.dropdown.noImages') }}</a-select-option>
          </a-select>
        </div>

        <div v-if="stage === DownloadStage.DOWNLOAD_IN_PROGRESS">
          <a-progress :percent="progressPercentage"
            :status="downloadIsReady ? undefined : 'active'"
            :show-info="false" stroke-color="#66c300"/>

          <p class="report-text report-body">
            {{ infoText }}
          </p>
        </div>

        <div class="download-button-container">
          <a-button
              v-if="stage === DownloadStage.CHOOSE"
              :disabled="selectedImagesRequest == undefined"
              class="mb-10 w170"
              type="primary"
              size="large" @click="startDownload">
              {{ $t('values.next') }}
          </a-button>

          <a-button
              v-if="stage !== DownloadStage.CHOOSE"
              class="mb-10 w170"
              type="primary"
              :disabled="!downloadIsReady"
              size="large"
              @click="download">
            {{ $t('values.download') }}
          </a-button>
        </div>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>

import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import axios from 'axios';
import { configForPossibleBackendRequest } from '../../../util/request';

const MAX_LOADING_TIME_SECS = 60 * 5; // 5 minutes

export default {
  name: 'OriginalSubmissionsDownloadModal',
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    onExit: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      DownloadStage: {
        CHOOSE: 'choose',
        DOWNLOAD_IN_PROGRESS: 'downloading'
      },
      stage: 'choose',
      selectedImagesRequest: undefined,
      isFetching: false,
      submissionsRequestTime: 0,
      submissionsRequestTimeIntervalId: null,
      fileName: 'original-submissions',
      submissionsBlob: undefined,
      abortController: undefined,
      imageCount: -1
    };
  },
  computed: {
    ...mapState({
      projectId: state => state.survey.project.id,
      surveyId: state => state.survey.surveyId,
      surveyError: state => state.survey.error
    }),
    progressPercentage() {
      return this.submissionsBlob ? 100 : Math.min(
        this.submissionsRequestTime / MAX_LOADING_TIME_SECS * 99, 99
      );
    },
    infoText() {
      return !this.isFetching && !!this.submissionsBlob
        ? this.$t('components.description.submissionsDownloadReady')
        : this.$t('components.description.thisCouldTakeUpToOneMinute');
    },
    downloadIsReady() {
      return !!this.submissionsBlob;
    },
    projectAndSurveyIdsLoaded() {
      return this.projectId != null && this.surveyId != null;
    }
  },
  watch: {
    projectAndSurveyIdsLoaded() {
      if (this.projectId && this.surveyId && this.imageCount === -1) {
        const axiosConfig = {
          method: 'GET',
          url: `/projects/${this.projectId}/survey/${this.surveyId}/submissions-image-count`
        };
        const token = this.$store.getters.token ? this.$store.getters.token : null;

        Vue.prototype.$http.request(
          configForPossibleBackendRequest(axiosConfig, token),
        ).then(response => {
          const { data } = response;
          this.imageCount = data.imagecount;
        });
      }
    },
    show(show) {
      if (show && this.imageCount === 0) {
        this.selectedImagesRequest = 'noImages';
        this.startDownload();
      }
    },
    imageCount(imageCount) {
      if (this.show && imageCount === 0) {
        this.selectedImagesRequest = 'noImages';
        this.startDownload();
      }
    }
  },
  async mounted() {
    if (this.projectId && this.surveyId && this.imageCount === -1) {
      const axiosConfig = {
        method: 'GET',
        url: `/projects/${this.projectId}/survey/${this.surveyId}/submissions-image-count`
      };
      const token = this.$store.getters.token ? this.$store.getters.token : null;

      Vue.prototype.$http.request(
        configForPossibleBackendRequest(axiosConfig, token),
      ).then(response => {
        const { data } = response;
        this.imageCount = data.imagecount;
      });
    }
  },
  methods: {
    ...mapActions([
      'triggerReportGeneration',
      'retrieveResource',
      'deleteResource'
    ]),
    cancelDownload() {
      this.stage = this.DownloadStage.CHOOSE;
      this.selectedImagesRequest = undefined;
      if (this.abortController && !this.abortController.signal.aborted) {
        this.abortController.abort();
      }
      clearInterval(this.submissionsRequestTimeIntervalId);
      this.submissionsRequestTime = 0;
      this.onExit();
    },
    async startProgressTimer() {
      this.submissionsRequestTime = 0;
      this.submissionsRequestTimeIntervalId = setInterval(() => {
        this.submissionsRequestTime += 1;
      }, 1000);
    },
    async startDownload() {
      this.submissionsBlob = undefined;
      this.isFetching = true;
      this.abortController = new AbortController();
      this.stage = this.DownloadStage.DOWNLOAD_IN_PROGRESS;

      const projectId = this.$store.state.survey.project.id;
      const token = this.$store.getters.loggedIn
        ? this.$store.state.request.data.session.token
        : null;
      const axiosConfig = {
        method: 'GET',
        url: `/projects/${projectId}/survey/${this.surveyId}/export-submissions?imageRequested=${this.selectedImagesRequest}`,
        responseType: 'blob',
        signal: this.abortController.signal
      };
      this.startProgressTimer();
      Vue.prototype.$http.request(
        configForPossibleBackendRequest(axiosConfig, token),
      ).then(response => {
        const { data, headers } = response;
        const contentDisposition = headers['content-disposition'];
        this.fileName = /filename="(.+)"/.exec(contentDisposition);
        this.submissionsBlob = new Blob([data], { type: headers['content-type'] });
      }).catch(error => {
        if (axios.isCancel(error)) {
          // Request canceled
          return true; // so we don't show the error message.
        }
        if (error.response.status === 409) {
          this.$alert().danger(this.$t('components.notifications.theSmartCollectAppWasNotUsedForDataCollection'));
          this.onExit();
          return true;
        }
        return false;
      }).finally(() => {
        clearInterval(this.submissionsRequestTimeIntervalId);
        this.isFetching = false;
      });
    },
    async download() {
      if (this.downloadIsReady) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(this.submissionsBlob);
        link.download = `${(this.fileName && this.fileName[1]) || 'original_submission'}`;
        link.click();
        URL.revokeObjectURL(link.href);
        link.remove();
      }
    }
  }
};
</script>


<style lang="scss">

.flag-picker {
  width: 320px;
  z-index: 999;
}

.download-button-container {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.report-text {
  font-family: 'DINNextLTPro-Regular';
  font-size: 16px;
  width: 100%;
  color: #363534;
  margin-top: 15px;
}

.report-body {
  margin-bottom: 50px;
}
</style>
